import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hydraulics Engineering`}</h1>
    <h2>{`Lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/0.Hydraulics-Engineering-introduction.pdf"
      }}>{`0.Hydraulics Engineering introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/1-specific-energy-and-critical-depth.pdf"
      }}>{`1 specific energy and critical depth`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/2-Flow-Over-Humps-and-through-Constrictions.pdf"
      }}>{`2 Flow Over Humps and through Constrictions`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/3Hydraulic-jump-and-its-practical-applications..pdf"
      }}>{`3 Hydraulic jump and its practical applications.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/4-Broad-Crested-Weirs-and-Venturi-Flumes.pdf"
      }}>{`4 Broad Crested Weirs and Venturi Flumes`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/5-Discharge-through-orifices-and-over-weirs-under-varying-heads..pdf"
      }}>{`5 Discharge through orifices and over weirs under varying heads.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/6-Unsteady-Flow-through-pipe-lines-Water-hammer.-Instantaneous-and-slow-closure-of-valves.pdf"
      }}>{`6 Unsteady Flow through pipe lines, Water hammer. Instantaneous and slow closure of valves`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/7-Surface-Profiles-and-Backwater-Curves-in-Channels-of-Uniform-sections.pdf"
      }}>{`7 Surface Profiles and Backwater Curves in Channels of Uniform sections`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/8.-Sediment-Transport.pdf"
      }}>{`8. Sediment Transport`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/9.-Introduction-to-Hydropower.pdf"
      }}>{`9. Introduction to Hydro-power`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/10.-Hydraulic-Similitude.ppt"
      }}>{`10. Hydraulic Similitude`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/11.0.Dams_engineeringReservoirs.pdf"
      }}>{`11.0.Dams engineering+Reservoirs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/11.1.Dams-reservoirs.ppt"
      }}>{`11.1.Dams & reservoirs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/11.2.-reservoirs.ppt"
      }}>{`11.2. Reservoirs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/12.Dimensional-Analysis-And-Similitude-Model-Analysis.ppt"
      }}>{`12.Dimensional Analysis And Similitude & Model Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/13.Surge-wave-in-open-channel.ppt"
      }}>{`13.Surge wave in open channel`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/14.-Unsteady-Flow.ppt"
      }}>{`14. Unsteady Flow`}</a></p>
    <h3>{`Important notes:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1tkmGixYeEb-0ozLsTzrYAvTgIElxL2uC"
      }}>{`Dr. Habib Portion, Hydraulics Engineering Hand made notes`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/bed-load-transport-and-theories.pdf"
      }}>{`Bed load transport and theories`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/initiation-of-bed-load-transport-and-theories.pdf"
      }}>{`Initiation of bed load transport and theories`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/open-channel-flow1.pdf"
      }}>{`Open channel flow`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/open-channels-problems.pdf"
      }}>{`Open channels problems`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/specific-energy2.pdf"
      }}>{`Specific energy`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/planning-of-water-storage-reservoirs.pdf"
      }}>{`Planning of water storage reservoirs`}</a></p>
    <h3>{`Solution to problems`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/solution-to-problems-ch-11-Q76-to-Q89-daugherty.-Made-by-Usman-Iftikhar.pdf"
      }}>{`Solution to problems ch 11 Q76 to Q89 Daugherty. Made by Usman Iftikhar`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/solution-to-problems-ch11-Q59-to-Q75-of-daugherty.-made-by-Usman-Iftikhar.pdf"
      }}>{`Solution to problems ch11 Q59 to Q75 of Daugherty. made by Usman Iftikhar`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Unsteady-flow-class-work-and-problems.pdf"
      }}>{`Unsteady flow class work and problems`}</a></p>
    <h2>{`Lab reports`}</h2>
    <h3>{`Lab Experiments:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/determination-of-mannings-roughness-coefficient-and-chezys-constant-of-laboratory-flume/"
      }}>{`Determination of Manning’s roughness coefficient and Chezy’s constant of laboratory flume`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/experimental-investigation-of-relationship-between-specific-energy-and-depth-of-flow/"
      }}>{`Experimental investigation of relationship between specific energy and depth of flow`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/to-study-flow-characteristics-over-a-hump-or-weir/"
      }}>{`Experimental study of flow characteristics over a hump or weir`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/experimental-study-of-characteristics-of-hydraulic-jump/"
      }}>{`Experimental study of characteristics of hydraulic jump`}</a></p>
    <h3>{`Design assignments:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/to-develop-relationship-between-surface-area-elevation-and-capacity-of-reservoir-to-plot-area-elevation-curve-elevation-capacity-curve-area-capacity-curve-and-combined-area-elevation-and-capacity/"
      }}>{`To develop relationship between surface area, elevation and capacity of reservoir (To plot area elevation curve, elevation capacity curve, area capacity curve and combined area, elevation and capacity curve of reservoir)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/estimation-of-live-storage-capacity-of-a-reservoir-for-various-operational-scenarios-plotting-mass-curves-mass-inflow-and-demand-curve-proposing-suitable-emptying-and-filling-program-of-reservoir/"
      }}>{`Estimation of live storage capacity of a reservoir for various operational scenarios. (Plotting mass curves (mass inflow and demand curve), proposing suitable emptying and filling program of reservoir)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/estimation-of-hydropower-potential-for-a-given-water-power-development-scheme/"
      }}>{`Estimation of hydropower potential for a given water power development scheme`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/to-estimate-bed-load-total-sediment-load-and-half-life-of-a-reservoir/"
      }}>{`To estimate bed load, total sediment load and half life of a reservoir`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/computation-of-gradually-varied-flow-profile-by-standard-step-method/"
      }}>{`Computation of gradually varied flow profile by standard step method`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      